
const Debuglog = (message, color) => {
    color = color || "black";
    switch (color) {
        case "success":
            color = "Green";
            break;
        case "info":
            color = "DodgerBlue";
            break;
        case "error":
            color = "Red";
            break;
        case "warning":
            color = "Orange";
            break;
        default:
            color = color;
    }
    console.log("%c" + message, "color:" + color);
}

export default Debuglog;