import Geolocation from '@react-native-community/geolocation';
export const getLocation = () => {
  return new Promise(async (resolve, rejects) => {
    Geolocation.getCurrentPosition(
      position => {
        // let location = position.coords.latitude + ',' + position.coords.longitude
        let dataReturn = {
          type: 'success',
          location: position.coords,
        };
        resolve(dataReturn);
      },
      error => {
        let dataReturn = {
          ...error,
          type: 'error',
          location: '0,0',
        };
        resolve(dataReturn);
        console.log('error getLocation : ' + JSON.stringify(dataReturn));
      },
      {enableHighAccuracy: false, timeout: 8000, maximumAge: 10000},
    );
  });
};
// export const requestPermissionLocation = () => {
//   return new Promise((resolve, rejects) => {
//     PERMISSIONS.request('location', {type: 'always'})
//       .then(status => {
//         console.log('request location : ' + status);
//         if (status == 'authorized') {
//           resolve(true);
//         } else {
//           resolve(false);
//         }
//       })
//       .catch(error => {
//         console.log('request location error : ' + error);
//         rejects(error);
//       });
//   });
// };
