export const apikey =
  "1kIva2Xo2JOmEpph6V_9M0oVz1FeNgPGypSW-N2FKeW9bSSue04zdmSzmBIIM9aqfHVWl5G61nyCOmy4-vdhL_JLCJe5g1tWdTHZwnhyROZZN2e6N0fZZN3o6UaHoK47JfFXlCMalQy33zobaR64WCrQjYQ466Jh4Cd3hunh3afJCz-5EhKxCgGXgbWWSXopwmA8djqsKKKH2nn1sZCp2xltShphcHGFWi-t0WZelqneThqf0DugrSffVzc5AnYJIJ3x84umxQwoXBnhBoiGNhq-PSJT1nX47l7GGVJzWQku4J-iDfN1tZDhFMex5OcMFY_anNUf9-NAKaO5ZwRplw"; //API Server Producttion
// export const min = 10000;
// export const max = 99999;
export const min = 10001;
export const max = 100000;
export const channel = "255b2c0de909d4b89c1929bac0e2b20b";
// export const channel = "255b2c0de909d4b80c1929bac0e2b22g";
// export const channel = "255b2c0de909d4b80c1929bac0e2b22g";
//DEV
// export const url_main = "https://easymoneydev.com/";
// export const url = "https://easymoneydev.com/wsemp/";

//PROD
export const url_main = "https://tungthanasin.co.th/";
// export const url = 'https://tungthanasin.co.th/wsemp/';
export const url =
  "https://auditliff.tungthanasin.co.th/adaptor.php?https://tungthanasin.co.th/wsemp/";
