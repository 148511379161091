import axios from "axios";
// import { url } from "./config";
// const BASE_URL = url;
// const BASE_URL = "https://easymoneydev.com/wsemp/";

const BASE_URL =
  "https://auditliff.tungthanasin.co.th/adaptor.php?https://tungthanasin.co.th/wsemp/";
const instanceMain = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    withCredentials: false,
  },
});
class Provider {
  api(options) {
    return new Promise(async function (resolve, reject) {
      let { type, url, data, headers } = options;
      let dataRes;
      switch (type) {
        case "GET":
          dataRes = await instanceMain.get(url, headers);
          break;
        case "SHOW":
          dataRes = await instanceMain.get(url);
          break;
        case "POST":
          dataRes = await instanceMain.post(url, data, headers);
          break;
        case "PUT":
          dataRes = await instanceMain.put(url, data, headers);
          break;
        case "DELETE":
          dataRes = await instanceMain.delete(url);
          break;
        default:
          break;
      }
      resolve(dataRes.data);
    });
  }
}

export default new Provider();
