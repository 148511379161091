import styled from 'styled-components';

export const clearfix = `*zoom: 1;&::after{ content: ""; display: table; }&::after{ content: ""; display: table; clear: both; }`
export const Row = styled.div`
        ${clearfix}
        ${props => props.flex ? "display:flex;" : null}
        float: none;
        height: auto;
        margin: 0px auto;
        clear: none;
        width: 100%;
        min-width: 0px;
        padding: ${props=> props.p0 ? "0px" : "10px"};
        ${props=> props.demo? "border: 1px solid rgb(205, 205, 205);" : null }`;
const getWidthColumn = props => props.width ? props.width : "50%";      
export const Column = styled.div`
        ${clearfix}
        float: left;
        height: auto;
        margin: 0px;
        clear: none;
        width: ${props=> getWidthColumn(props)};
        ${props=> props.demo? "border: 1px solid rgb(205, 205, 205);" : null }
        ${props => 
                props.noRes ? null : 
                `@media only screen and (max-width: 370px) {
                        width: 100%;
                        margin-left: auto;
                        margin-right: auto;
                        float: none;
                        clear: both;
                        margin-top: 0px;
                        height: auto;
                }`}
        `;
export const ColumnFlex = styled.div`
        ${clearfix}
        float: left;
        height: auto;
        margin: 0px;
        clear: none;
        ${props=> props.flex ? `flex : ${props.flex};` : null }
        ${props=> props.basis ? `flex-basis: ${getWidthColumn({width:props.basis})};` : null }
        ${props=> props.demo? "border: 1px solid rgb(205, 205, 205);" : null }`;