import React from "react";
import styled, { keyframes } from "styled-components";

const animatetopx = keyframes`
    from {top:-300px; opacity:0}
    to {top:0; opacity:1}
`;

const animatebotx = keyframes`
    from {bottom:-100px; opacity:0}
    to {bottom:0; opacity:1}
`;
const ModalBackDrop = styled.div`
  display: ${props =>
    props.open === true ? "block" : "none"}; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 999; /* Sit on top */
  padding-top: 185px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.6); /* Black w/ opacity */
`;
const ModalContent = styled.div`
    position: relative;
    background-color: rgba(0,0,0,0.8);
    margin: auto;
    padding: ${props => (props.padding !== undefined ? props.padding : "0")};
    ${"" /* border: px solid #888; */}
    width: ${props => (props.size !== undefined ? props.size : "91%")};
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    -webkit-animation-name: ${props =>
      props.isBottom ? animatebotx : animatetopx};
    -webkit-animation-duration: 0.4s;
    animation-name: ${props => (props.isBottom ? animatebotx : animatetopx)};
    animation-duration: 0.4s;
    `;

export default class ModalAlert extends React.Component {
  render() {
    const { open, children, size, style, isBottom, padding } = this.props;
    return (
      <ModalBackDrop open={open}>
        <ModalContent
          size={size}
          style={style}
          isBottom={isBottom}
          padding={padding}
        >
          {children}
        </ModalContent>
      </ModalBackDrop>
    );
  }
}
