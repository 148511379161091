// import Provider from './globalService/Provider-Api';
import Provider from "../Global/Provider-Api";
import { apikey, min, max, channel } from "../Global/config";
import cryptojs from "crypto-js";
import colorLog from "../Global/debuglog";

class Home {
  ganeratesig = (action, module, method, condition, data) => {
    let nonce = this.nonceFunc();
    let sig = action + module + method + condition + data + nonce;
    var hash = cryptojs.HmacSHA256(sig, apikey);
    const encode = {
      nonce: nonce,
      encypted: hash,
    };
    return encode;
  };
  nonceFunc = () => {
    let microtime = Date.now() * 100;
    let rand =
      (Math.floor(Math.random() * max) + min).toString().length === 5
        ? Math.floor(Math.random() * max) + min
        : (Math.floor(Math.random() * max) + min).toString().substring(0, 5);
    if (rand.toString().length > 5) {
      let microData = microtime.toString() + "12345";
      return microData;
    } else {
      let microData = microtime.toString() + rand.toString();
      return microData;
    }
  };
  headerFunc = (jwt, jsondata) => {
    let hash = cryptojs.HmacSHA512(jwt + jsondata, apikey);
    return hash;
  };

  getProvider = (jwt, condition, field, module, method) => {
    colorLog(
      "-----------------------GET-Provider---------------------",
      "Orange",
      jwt
    );
    colorLog("TYPE:" + "POST", "Orange");
    colorLog("CONDITION:" + JSON.stringify(condition), "Orange");
    colorLog("FIELD:" + JSON.stringify(field), "Orange");
    colorLog("MODULE:" + JSON.stringify(module), "Orange");
    colorLog("METHOD:" + JSON.stringify(method), "Orange");
    let type = "GET";
    let json_condition = JSON.stringify(condition);
    let json_field = JSON.stringify(field);
    let encypted = this.ganeratesig(
      "get",
      module,
      method,
      json_condition,
      json_field
    );
    let get_url =
      "get/" +
      module +
      "/" +
      method +
      "/" +
      json_condition +
      "/" +
      json_field +
      "/" +
      encypted.nonce +
      "/" +
      encypted.encypted;
    let jsonbody = "";
    let signature = this.headerFunc(jwt, jsonbody);
    let option = {
      headers: {
        "X-Authorization": "Bearer " + jwt,
        "X-Signature": "" + signature,
        "Content-Type": "application/json",
        // Referer: "easymoney.lineliff",
      },
    };
    let data = field;
    return { type, get_url, option, data };
  };

  postProvider = (jwt, field, condition, module, method) => {
    colorLog(
      "-----------------------POST-Provider---------------------",
      "Orange"
    );
    colorLog("TYPE:" + "POST", "Orange");
    colorLog("CONDITION:" + JSON.stringify(condition), "Orange");
    colorLog("FIELD:" + JSON.stringify(field), "Orange");
    colorLog("MODULE:" + JSON.stringify(module), "Orange");
    colorLog("METHOD:" + JSON.stringify(method), "Orange");
    let type = "POST";
    let json_condition = "";
    let json_field = JSON.stringify(field);
    let encypted = this.ganeratesig(
      "post",
      module,
      method,
      json_condition,
      json_field
    );
    let get_url =
      "post/" +
      module +
      "/" +
      method +
      "/" +
      json_field +
      "/" +
      encypted.nonce +
      "/" +
      encypted.encypted;
    let signature = this.headerFunc(jwt, json_field);

    let option = {
      headers: {
        "X-Authorization": "Bearer " + jwt,
        "X-Signature": "" + signature,
        "Content-Type": "application/json",
        // Referer: "easymoney.lineliff",
      },
    };
    let data = json_field;
    return { type, get_url, option, data };
  };
  putProvider = (jwt, field, condition, module, method) => {
    colorLog(
      "-----------------------PUT-Provider---------------------",
      "Orange"
    );
    let type = "PUT";
    let json_condition = JSON.stringify(condition);
    let json_field = JSON.stringify(field);
    let encypted = this.ganeratesig(
      "put",
      module,
      method,
      json_condition,
      json_field
    );
    let get_url =
      "put/" +
      module +
      "/" +
      method +
      "/" +
      json_condition +
      "/" +
      json_field +
      "/" +
      encypted.nonce +
      "/" +
      encypted.encypted;
    let signature = this.headerFunc(jwt, json_field);
    let option = {
      headers: {
        "X-Authorization": "Bearer " + jwt,
        "X-Signature": "" + signature,
        "Content-Type": "application/json",
        // Referer: "easymoney.lineliff",
      },
    };
    let data = json_field;
    return { type, get_url, option, data };
  };

  // loadStartJWT
  async loadStartJWT() {
    let arrstart = { channel: channel };
    let jsoncdata = JSON.stringify(arrstart);
    let encypted = this.ganeratesig("start", "startprogram", "", jsoncdata, "");
    let get_url =
      "start/startprogram/" +
      jsoncdata +
      "/" +
      encypted.nonce +
      "/" +
      encypted.encypted;
    console.log("get_url", get_url);

    let dataRes = await Provider.api({
      type: "GET",
      url: get_url,
      data: {},
    });
    // console.log(dataRes);

    colorLog("FROM API --> " + JSON.stringify(dataRes), "info");
    colorLog(
      "----------------------------END--------------------------",
      "Orange"
    );
    return dataRes;
  }

  async exlogin(jwt, username, password) {
    let condition = "";
    let field = { user_name: username, password: password };
    let module = "excheckin";
    let method = "exlogin";
    const { type, get_url, option, data } = this.postProvider(
      jwt,
      field,
      condition,
      module,
      method
    );
    let dataRes = await Provider.api({
      type: type,
      url: get_url,
      headers: option,
      data,
    });
    colorLog("FROM APIs --> " + JSON.stringify(dataRes), "info");
    colorLog(
      "----------------------------END--------------------------",
      "Orange"
    );
    return dataRes;
  }
  async savecheckin(jwt, branch_id) {
    let condition = "";
    let field = { branch_id: branch_id };
    let module = "excheckin";
    let method = "savecheckin";
    const { type, get_url, option, data } = this.postProvider(
      jwt,
      field,
      condition,
      module,
      method
    );
    let dataRes = await Provider.api({
      type: type,
      url: get_url,
      headers: option,
      data,
    });
    colorLog("FROM APIs --> " + JSON.stringify(dataRes), "info");
    colorLog(
      "----------------------------END--------------------------",
      "Orange"
    );
    return dataRes;
  }
  async exbranch(jwt) {
    let condition = {};
    let field = {};
    let module = "excheckin";
    let method = "exbranch";
    const { type, get_url, option, data } = this.getProvider(
      jwt,
      condition,
      field,
      module,
      method
    );
    let dataRes = await Provider.api({
      type: type,
      url: get_url,
      headers: option,
      data,
    });
    // dataRes.data = dataRes.data.map((v) => {
    //   return {
    //     id: v.id,
    //     value: v.id,
    //     label: v.name_th,
    //   };
    // });
    // colorLog("FROM APIs --> " + JSON.stringify(dataRes), "info");
    colorLog(
      "----------------------------END--------------------------",
      "Orange"
    );
    return dataRes;
  }

  async getcheckinhistory(jwt) {
    let condition = {};
    let field = {};
    let module = "excheckin";
    let method = "getcheckinhistory";
    const { type, get_url, option, data } = this.getProvider(
      jwt,
      condition,
      field,
      module,
      method
    );
    // console.log(option);
    let dataRes = await Provider.api({
      type: type,
      url: get_url,
      headers: option,
      data,
    });
    colorLog(
      "----------------------------END--------------------------",
      "Orange"
    );
    return dataRes;
  }
  distance = (lat1, lon1, lat2, lon2, unit) => {
    if (lat1 == lat2 && lon1 == lon2) {
      return 0;
    } else {
      var radlat1 = (Math.PI * lat1) / 180;
      var radlat2 = (Math.PI * lat2) / 180;
      var theta = lon1 - lon2;
      var radtheta = (Math.PI * theta) / 180;
      var dist =
        Math.sin(radlat1) * Math.sin(radlat2) +
        Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = (dist * 180) / Math.PI;
      dist = dist * 60 * 1.1515;
      if (unit == "K") {
        dist = dist * 1.609344;
      }
      if (unit == "N") {
        dist = dist * 0.8684;
      }
      // console.log('-----dist----', dist);
      return dist;
    }
  };
  async getBranchcontact(jwt, latitude, longitude) {
    let condition = {};
    let field = {};
    let module = "contactus";
    let method = "getBranchcontact";
    const { type, get_url, option, data } = this.getProvider(
      jwt,
      condition,
      field,
      module,
      method
    );
    let dataRes = await Provider.api({
      type: type,
      url: get_url,
      headers: option,
      data,
    });

    dataRes.data.branch = dataRes.data.branch.map((b) => {
      b.branch_km = parseFloat(
        this.distance(
          latitude,
          longitude,
          b.location[0],
          b.location[1],
          "K"
        ).toFixed(3)
      );

      return b;
    });
    // colorLog("FROM APIs --> " + JSON.stringify(dataRes), "info");
    // colorLog(
    //   "----------------------------END--------------------------",
    //   "Orange"
    // );
    return dataRes;
  }
}

export default new Home();
