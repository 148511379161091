import React, { Component } from "react";
import axios from "axios";
// import { Button, Form } from "react-bootstrap";
import Helper from "../Global/Helper";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link,
  Redirect,
} from "react-router-dom";
import ModalAlert from "../Components/ModalAlert";
import liffHelper from "../utils/liffHelper";
import messageHelper from "../utils/messagingApiHelper";
import logo_head from "./images/logo.png";
import appbanner from "./images/appbanner.png";
import Loader from "react-loader-spinner";
import Service from "../services/home";
import exit from "./images/exit.png";
import closepop from "./images/closepop.png";
import check from "./images/check.png";
import { geolocated } from "react-geolocated";
import moment from "moment";
import { orderBy } from "natural-orderby";
import { getLocation } from "../common/commonFunction";
import "moment/locale/th"; // without this line it didn't work
moment.locale("th");

const liff = window.liff;
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jwt: null,
      page: "Login",
      msg: "",
      username: "",
      password: "",
      // profile: {},
      Date_TH: "",
      Date_TH_MINUTE: "",
      location_branch: [],
      branch_item: [],
      history_branch: [],
      branch_select: "",
      active_forget_pass: false,
      active_login_alert: false,
      active_branch: false,
      active_submit_btn: false,
      active_check_alert: false,
      active_checkin_btn: false,
      textSearch: "",
      dataContactFilter: undefined,
      autoLocationLoad: false,
    };

    this.lnw = this.lnw.bind(this);
    this.onStart = this.onStart.bind(this);
    this.actionStart = this.actionStart.bind(this);
    this._openForgetPass = this._openForgetPass.bind(this);
    this._closeForgetPass = this._closeForgetPass.bind(this);
    this._closeAlertLogin = this._closeAlertLogin.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
    this._getExBranch = this._getExBranch.bind(this);
    this.logOut = this.logOut.bind(this);
    this._getHistory = this._getHistory.bind(this);
    this._openBranch = this._openBranch.bind(this);
    this._closeBranch = this._closeBranch.bind(this);
    this._selectBranch = this._selectBranch.bind(this);
    this._onSelectBranch = this._onSelectBranch.bind(this);
    this._openAlert = this._openAlert.bind(this);
    this._closeAlert = this._closeAlert.bind(this);
    this.mySearch = this.mySearch.bind(this);
    this._submitLocation = this._submitLocation.bind(this);
    this.readLocation = this.readLocation.bind(this);
  }
  toBuddhistYear = (moment, format) => {
    var christianYear = moment.format("YYYY");
    var buddhishYear = (parseInt(christianYear) + 543).toString();
    return moment
      .format(
        format
          .replace("YYYY", buddhishYear)
          .replace("YY", buddhishYear.substring(2, 4))
      )
      .replace(christianYear, buddhishYear);
  };
  AutoLocation = () => {
    let { dataContactFilter, location_branch_filter } = this.state;
    let id_location = dataContactFilter.branch_id;
    let auto_filter = location_branch_filter.filter(
      (item) => item.branch_id === id_location
    );
    if (auto_filter.length === 1) {
      this.setState({
        autoLocationLoad: false,
        branch_select: id_location,
        branch_item: auto_filter[0],
      });
    } else {
      this.setState({
        autoLocationLoad: false,
      });
    }
  };
  onStart() {
    let check_login = localStorage.getItem("IS_LOGIN");
    if (check_login === null) {
      this.actionStart();
    } else if (check_login === "1") {
      this.setState(
        {
          page: "Home",
          autoLocationLoad: true,
        },
        () => {
          this._getExBranch();
          this.readLocation();
          this._getHistory();
        }
      );
    } else {
      this.actionStart();
    }
    var dateServerToHandleYesterday = new Date();
    var tomorrow = new Date(
      dateServerToHandleYesterday.setDate(dateServerToHandleYesterday.getDate())
    );
    let Date_TH = this.toBuddhistYear(moment(tomorrow), "DD MMM YYYY");
    let update_date_minutes = moment(tomorrow).format("HH:mm");
    this.setState({
      Date_TH: Date_TH,
      Date_TH_MINUTE: update_date_minutes,
    });
    navigator.geolocation.getCurrentPosition(
      (position) => {
        this.setState({
          location: position.coords.latitude + "," + position.coords.longitude,
        });
      },
      (error) => console.log(error)
    );
  }
  componentDidMount() {
    this.onStart();
    // fetch(
    //   "https://api.telegram.org/bot1081008618:AAE5iEXu845qdrlhKsLJJjgMKrTeRLE6o0I/sendMessage?chat_id=-1001432601444&text=" +
    //     JSON.stringify({
    //       function: "componentDidMount",
    //     })
    // );
  }
  async actionStart() {
    try {
      const { data, status } = await Service.loadStartJWT();
      if (status === "Success") {
        if (data !== null) {
          this.setState(
            {
              jwt: data,
            },
            () => {
              localStorage.setItem("jwt", data);
            }
          );
        } else {
        }
      } else {
      }
    } catch (error) {}
  }
  _openForgetPass() {
    this.setState({
      active_forget_pass: true,
    });
  }
  _closeForgetPass() {
    this.setState({
      active_forget_pass: false,
    });
  }
  _closeAlertLogin() {
    this.setState({
      active_login_alert: false,
    });
  }

  async _onSubmit() {
    let jwt = localStorage.getItem("jwt");
    const { username, password } = this.state;
    try {
      const { data, status, msg } = await Service.exlogin(
        jwt,
        username,
        password
      );
      if (status === "Success") {
        if (data.auth.login_status === true) {
          localStorage.setItem("jwt", data.auth.jwt);
          localStorage.setItem("IS_LOGIN", "1");
          localStorage.setItem("USERNAME", username);
          localStorage.setItem("PASSWORD", password);
          localStorage.setItem("profile", JSON.stringify(data.auth.profile));
          this._getExBranch();
          this._getHistory();
          this.readLocation();
          setTimeout(() => {
            this.setState({
              page: "Home",
            });
          }, 500);
        }
      } else {
        this.setState({
          msg: msg,
          active_login_alert: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }
  async _getExBranch() {
    let jwt = localStorage.getItem("jwt");

    try {
      const { data, status, msg } = await Service.exbranch(jwt);
      if (status === "Success") {
        this.setState(
          {
            location_branch_filter: orderBy(
              data.result,
              [(v) => v.branch_id, (v) => v.branch_name],
              ["asc", "asc"]
            ),
            location_branch: orderBy(
              data.result,
              [(v) => v.branch_id, (v) => v.branch_name],
              ["asc", "asc"]
            ),
          },
          () => {}
        );
      } else {
        this.setState({
          location_branch: [],
          location_branch_filter: [],
          autoLocationLoad: false,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }
  logOut() {
    this.setState(
      {
        page: "Login",
      },
      () => {
        localStorage.clear();
        setTimeout(() => {
          this.actionStart();
        }, 500);
      }
    );
  }
  async _getHistory() {
    let jwt = localStorage.getItem("jwt");
    try {
      const { data, status, msg } = await Service.getcheckinhistory(jwt);
      if (status === "Success") {
        this.setState({
          history_branch: orderBy(
            data.result,
            [(v) => v.create_date, (v) => v.create_timeshow],
            ["desc", "desc"]
          ),
        });
      } else {
        this.setState({
          history_branch: [],
        });
      }
    } catch (error) {
      console.log(error);
    }
  }
  _openBranch() {
    this.setState({
      active_branch: true,
    });
  }
  _closeBranch() {
    this.setState({
      active_branch: false,
      branch_select: "",
      branch_item: [],
    });
  }
  _selectBranch() {
    this.setState({
      active_branch: false,
    });
  }
  _onSelectBranch(item) {
    let { branch_select } = this.state;
    if (branch_select === item.branch_id) {
      this.setState({
        branch_select: "",
        branch_item: [],
      });
    } else {
      this.setState({
        branch_select: item.branch_id,
        branch_item: item,
      });
    }
  }
  _openAlert() {
    this.setState({
      active_check_alert: true,
    });
  }
  _closeAlert() {
    this.setState({
      active_check_alert: false,
    });
  }
  onSearch = (data) => {
    this.setState({
      location_branch_filter: data,
    });
  };
  mySearch(text) {
    let { location_branch } = this.state;
    const newData = location_branch.filter(function (item) {
      const itemData = item.branch_name
        ? item.branch_name.toUpperCase()
        : "".toUpperCase();
      const textData = text.toUpperCase();
      return itemData.indexOf(textData) > -1;
    });
    this.onSearch(newData);
  }

  async _submitLocation() {
    let { branch_item } = this.state;
    let jwt = localStorage.getItem("jwt");
    const { username, password } = this.state;
    try {
      const { data, status, msg } = await Service.savecheckin(
        jwt,
        branch_item.branch_id
      );
      if (status === "Success") {
        this.setState(
          {
            page: "Success",
            active_check_alert: false,
            branch_select: "",
            branch_item: [],
          },
          () => {
            this._getHistory();
          }
        );
      } else {
        this.setState({
          active_check_alert: false,
          branch_select: "",
          branch_item: [],
        });
      }
    } catch (error) {
      this.setState({
        active_check_alert: false,
      });
    }
  }
  async readLocation() {
    let jwt = localStorage.getItem("jwt");
    let { dataContactFilter } = this.state;
    try {
      let data_location = await getLocation();
      const { location } = data_location;
      try {
        const { data, status } = await Service.getBranchcontact(
          jwt,
          location.latitude,
          location.longitude
        );
        data.branch.sort((a, b) =>
          a.branch_km > b.branch_km ? 1 : b.branch_km > a.branch_km ? -1 : 0
        );
        if (status === "Success") {
          const { branch } = data;
          // console.log('branch',branch);
          this.setState(
            {
              dataContact: branch,
              dataContactFilter: branch[0],
              branch_km: branch[0].branch_km,
              branch_name: branch[0].branch_name,
              // autoLocationLoad: true,
            },
            () => {
              setTimeout(() => {
                this.AutoLocation();
              }, 4000);
            }
          );
        }
      } catch (error) {
        this.setState({
          autoLocationLoad: false,
        });
      }
    } catch (e) {
      alert("Failed to fetch getbranchs");
    }
  }

  lnw() {
    localStorage.clear();
    this.onStart();
  }
  render() {
    const {
      autoLocationLoad,
      page,
      msg,
      username,
      password,
      active_forget_pass,
      active_submit_btn,
      active_login_alert,
      active_check_alert,
      active_checkin_btn,
      active_branch,
      history_branch,
      Date_TH,
      Date_TH_MINUTE,
      location_branch,
      branch_select,
      branch_item,
      branch_name,
      dataContactFilter,
      textSearch,
      location_branch_filter,
    } = this.state;

    let profile = localStorage.getItem("profile");
    let jwt = localStorage.getItem("jwt");
    // console.log("dataContactFilter", dataContactFilter);
    // console.log("autoLocationLoad", autoLocationLoad);
    return (
      <body>
        {page === "Login" ? (
          <section className="login">
            <div className="login_gimmic"></div>
            <b className="login_head">เข้าสู่ระบบ</b>
            <span className="login_subhead">ระบบ Check-In ผู้ตรวจทรัพย์</span>
            <div className="login_box">
              <div className="form-group">
                {/* <label for="">{jwt}</label> */}
                <label htmlFor="">รหัสพนักงาน</label>
                <input
                  type="text"
                  className="form-control requiredfill "
                  placeholder="Username"
                  name="username"
                  maxLength="7"
                  value={username}
                  onChange={(e) => {
                    let replaceInput = e.currentTarget.value.replace(
                      /[!$%^*()_+></?=\;#]/g,
                      ""
                    );
                    if (replaceInput !== "" && password !== "") {
                      this.setState({
                        username: replaceInput,
                        active_submit_btn: true,
                      });
                    } else {
                      this.setState({
                        username: replaceInput,
                        active_submit_btn: false,
                      });
                    }
                  }}
                />
                <span className="errmsg mainerrmsg"></span>
              </div>
              <div className="form-group">
                <label htmlFor="">รหัสผ่าน</label>
                <input
                  type="password"
                  className="form-control requiredfill "
                  placeholder="Password"
                  name="pass"
                  value={password}
                  onChange={(e) => {
                    let replaceInput = e.currentTarget.value.replace(
                      /[!$%^*()+></_?=\;#]/g,
                      ""
                    );
                    if (replaceInput !== "" && password !== "") {
                      this.setState({
                        password: e.currentTarget.value,
                        active_submit_btn: true,
                      });
                    } else {
                      this.setState({
                        password: e.currentTarget.value,
                        active_submit_btn: false,
                      });
                    }
                  }}
                />
                <span className="errmsg mainerrmsg"></span>
                <a
                  href="javascript:void(0)"
                  className="forgetpass"
                  onClick={() => {
                    this._openForgetPass();
                    this.lnw();
                  }}
                >
                  ลืมรหัสผ่าน ?
                </a>
              </div>

              {/* <div
                  href="javascript:void(0)"
                  className="forgetpass"
                  onClick={() => {
                    this.lnw();
                  }}
                >
                  ปุ่มเทพอย่าเพิ่งกดดนะ
                </div> */}
              {active_submit_btn === true ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <button
                    style={{
                      backgroundColor: "#a5e1ad",
                      borderRadius: 50,
                      borderWidth: 0,
                      color: "#fff",
                      fontSize: 16,
                      paddingLeft: "15%",
                      paddingRight: "15%",
                      paddingTop: "2%",
                      paddingBottom: "2%",
                      width: "100%",
                      marginTop: 15,
                    }}
                    onClick={() => {
                      this._onSubmit();
                    }}
                  >
                    เข้าสู่ระบบ
                  </button>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <button
                    style={{
                      width: "100%",
                      backgroundColor: "#aaa",
                      borderRadius: 50,
                      borderWidth: 0,
                      color: "#fff",
                      fontSize: 16,
                      marginTop: 15,
                      paddingLeft: "15%",
                      paddingRight: "15%",
                      paddingTop: "2%",
                      paddingBottom: "2%",
                    }}
                  >
                    เข้าสู่ระบบ
                  </button>
                </div>
              )}
            </div>
            {active_forget_pass === true ? (
              <div className="popup forgetpass">
                <div className="popup_box text-center">
                  <i className="fal fa-map-marker-question"></i>
                  <b>ต้องทำอย่างไรเมื่อลืมรหัสผ่าน ?</b>
                  <p>
                    กรณีที่คุณลืมรหัสผ่าน กรุณาติดต่อไปยังฝ่าย IT
                    เพื่อขอรับรหัสผ่านใหม่
                  </p>

                  <button
                    className="btn red"
                    onClick={() => {
                      this._closeForgetPass();
                    }}
                  >
                    รับทราบแล้ว
                  </button>
                </div>
              </div>
            ) : null}
            {active_login_alert === true ? (
              <div className="popup error wrong_user">
                <div className="popup_box text-center">
                  <i className="far fa-times"></i>
                  <b>
                    รหัสพนักงาน หรือรหัสผ่าน <br />
                    ของคุณไม่ถูกต้อง
                  </b>
                  <p>กรุณาตรวจสอบข้อมูลใหม่อีกครั้ง</p>

                  <button
                    className="btn red"
                    onClick={() => {
                      this._closeAlertLogin();
                    }}
                  >
                    ลองใหม่อีกครั้ง
                  </button>
                </div>
              </div>
            ) : null}
          </section>
        ) : null}
        {page === "Home" ? (
          <section className="home">
            <div className="home_gimmic"></div>
            <div className="header">
              <div className="row">
                <div className="col-8">
                  <b>ระบบ Check-In ผู้ตรวจทรัพย์</b>
                </div>
                <div className="col-4 text-right">
                  <a
                    className="moremenu"
                    onClick={() => {
                      this.setState({
                        page: "Menu",
                      });
                    }}
                  >
                    <i className="far fa-bars"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="user">
              <b>
                ผู้ตรวจ :
                {" " +
                  JSON.parse(profile).firstname +
                  " " +
                  JSON.parse(profile).lastname}
              </b>
              <span>
                วันที่ : <font className="dateshow">{Date_TH}</font>
              </span>
              <span>
                เวลา :{" "}
                <font className="digital-clock">{Date_TH_MINUTE} น.</font>
              </span>
            </div>
            <div className="checkin">
              <b>คุณกำลังอยู่ที่สาขา</b>
              <p>{branch_name}</p>
              {autoLocationLoad === true ? (
                <div>
                  <p>
                    <Loader
                      type="BallTriangle"
                      color="#12CAD6"
                      height={35}
                      width={35}
                      timeout={30000}
                    />
                  </p>
                </div>
              ) : (
                <div className="form-group">
                  <label htmlFor="">กดเพื่อเลือกสาขาที่คุณกำลังตรวจ</label>
                  {branch_item.length === 0 ? (
                    <input
                      type="text"
                      className="form-control branch_name-text"
                      placeholder="กรุณาเลือกสาขา"
                      value={""}
                      readOnly
                      onClick={() => {
                        this._openBranch();
                      }}
                    />
                  ) : (
                    <input
                      type="text"
                      className="form-control branch_name-text"
                      placeholder="กรุณาเลือกสาขา"
                      value={branch_item.branch_name}
                      readOnly
                      onClick={() => {
                        this._openBranch();
                      }}
                    />
                  )}
                </div>
              )}
              <div className="btncover">
                {branch_item.length === 0 ? (
                  <button className="btn green gosubmit disabled ">
                    CHECK IN
                  </button>
                ) : (
                  <button
                    className="btn green gosubmit "
                    onClick={() => {
                      this._openAlert();
                    }}
                  >
                    CHECK IN
                  </button>
                )}
              </div>
            </div>
            {active_branch === true ? (
              <div className="branch ontop">
                <div className="branch_search">
                  <input
                    type="text"
                    placeholder="พิมพ์สาขาที่คุณต้องการค้นหา..."
                    id="branch_search"
                    value={textSearch}
                    onChange={(e) => {
                      let replaceInput = e.currentTarget.value.replace(
                        /[!$%^*()+></?=\;#]/g,
                        ""
                      );

                      this.setState(
                        {
                          textSearch: replaceInput,
                        },
                        () => {
                          if (replaceInput.trim().length > 1) {
                            this.mySearch(replaceInput);
                          } else {
                            this.onSearch(location_branch);
                          }
                        }
                      );
                    }}
                  />
                  <div className="branch_search-icon">
                    <i className="fal fa-search"></i>
                  </div>
                </div>
                <div className="branch_list">
                  {location_branch_filter.map((item, index) => (
                    <div key={index}>
                      {branch_select === item.branch_id ? (
                        <div
                          className="branch_list-list select"
                          onClick={() => {
                            this._onSelectBranch(item);
                          }}
                        >
                          {item.branch_name}
                        </div>
                      ) : (
                        <div
                          className="branch_list-list"
                          onClick={() => {
                            this._onSelectBranch(item);
                          }}
                        >
                          {item.branch_name}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
                <div className="btncover">
                  <button
                    className="btn green"
                    onClick={() => {
                      this._selectBranch();
                    }}
                  >
                    เลือกสาขานี้
                  </button>
                  <button
                    className="btn red"
                    onClick={() => {
                      this._closeBranch();
                    }}
                  >
                    ยกเลิก
                  </button>
                </div>
              </div>
            ) : null}
            {active_check_alert === true ? (
              <div className="popup error confirm-checkin">
                <div className="popup_box text-center">
                  <i className="fal fa-map-marker-question"></i>
                  <b>
                    ยืนยันการ Check-In ที่สาขานี้ <br /> ใช่หรือไม่ ?
                  </b>
                  <p>กรุณาตรวจสอบชื่อสาขาให้ตรงกับที่ต้องการ ก่อนกดยืนยัน</p>

                  {active_checkin_btn === false ? (
                    <button
                      className="btn green"
                      onClick={() => {
                        this.setState(
                          {
                            active_checkin_btn: true,
                          },
                          () => {
                            setTimeout(() => {
                              this.setState({
                                active_checkin_btn: false,
                              });
                            }, 3000);
                            this._submitLocation();
                          }
                        );
                      }}
                    >
                      ยืนยันการ Check-In
                    </button>
                  ) : (
                    <button className="btn green disabled">
                      ยืนยันการ Check-In
                    </button>
                  )}

                  <button
                    className="btn red"
                    onClick={() => {
                      this._closeAlert();
                    }}
                  >
                    ขอตรวจสอบก่อน
                  </button>
                </div>
              </div>
            ) : null}
          </section>
        ) : null}
        {page === "Success" ? (
          <section className="success">
            <div className="success_gimmic"></div>
            <div className="success_box">
              <i className="far fa-check"></i>
              <b>Check-In สาขาที่คุณเลือกสำเร็จแล้ว</b>
              <span>
                ระบบจะทำการส่งข้อมูลแจ้งไปยัง Line ของกลุ่ม <br />{" "}
                ผู้ตรวจทรัพย์ให้ทราบถึงข้อมูลนี้
              </span>
              <div className="btncover">
                <button
                  className="btn green gosubmit"
                  onClick={() => {
                    this.setState({
                      page: "Home",
                    });
                  }}
                >
                  กลับสู่หน้าแรก
                </button>
              </div>
            </div>
          </section>
        ) : null}
        {page === "Menu" ? (
          <section className="page">
            <div className="page_gimmic"></div>
            <div className="header">
              <div className="row">
                <div className="col-12">
                  <button
                    className="backpage"
                    onClick={() => {
                      this.setState({
                        page: "Home",
                      });
                    }}
                  >
                    <i className="far fa-arrow-left"></i>
                  </button>

                  <b>เมนูอื่นๆ</b>
                </div>
              </div>
            </div>
            <div className="menu">
              <a
                className="menu_box"
                onClick={() => {
                  this.setState({
                    page: "History",
                  });
                }}
              >
                <i className="fal fa-history"></i>
                <span>ประวัติการ Check-In ของคุณ</span>
              </a>
              <a
                className="menu_box"
                onClick={() => {
                  this.logOut();
                }}
              >
                <i className="fal fa-sign-out-alt"></i>
                <span>ออกจากระบบ</span>
              </a>
            </div>
          </section>
        ) : null}
        {page === "History" ? (
          <section className="page">
            <div className="page_gimmic"></div>
            <div className="header">
              <div className="row">
                <div className="col-12">
                  <button
                    className="backpage"
                    onClick={() => {
                      this.setState({
                        page: "Menu",
                      });
                    }}
                  >
                    <i className="far fa-arrow-left"></i>
                  </button>
                  <b>ประวัติการ Check-In ของคุณ</b>
                </div>
              </div>
            </div>
            <div className="history">
              {history_branch.map((item, index) => (
                <div key={index}>
                  <div className="history_box">
                    <b className="history_date">{item.create_dateshow}</b>
                    <b className="history_time">{item.create_timeshow}</b>
                    <span className="history_branch">{item.branch_name}</span>
                  </div>
                </div>
              ))}
            </div>
          </section>
        ) : null}
      </body>
    );
  }
}
export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,
})(Home);
